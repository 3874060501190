import React from 'react';
import ReactDOM from 'react-dom';
import '../src/styles/ats.css'
import { configureAPI } from './services/api';
import { Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import CustomThemeProvider from './CustomThemeProvider'
import { Provider } from 'react-redux';
import { store } from './store/store';
import Ats from './Ats';

configureAPI(process.env.REACT_APP_BASE_URL);
ReactDOM.render(
  <Provider store={store}>

  <CustomThemeProvider >
    <>
  <Layout
    className={`h-screen flex fixed sm:w-full md:w-full w-full `}
    style={{ minHeight: "100vh" }}
  >
    <Content className="overflow-auto h-full bg-white">
      <Ats />
    </Content>
      </Layout>
    </>
  </CustomThemeProvider>
  </Provider>
  ,
  document.getElementById('root')
);
