import { createSlice } from "@reduxjs/toolkit";
import {  OrgInfo } from "../../../types/type";

interface initialStateType {
  orgInfo: OrgInfo;
  previewLoading:boolean;
  isOrgExist:boolean;
  isOrgCorrect: boolean;
}

const initialState: initialStateType = {
  orgInfo: {
    active: true,
    data: {
      company_size: "",
      description: "",
      facebook: "",
      insta: "",
      linkedin: "",
      location: "",
      mission_statement: "",
      twitter: "",
      type: true,
      website: "",
      youtube: "",
      logo: "",
    },
    id: "",
    name: "",
    state: "",
    tenantId: "",
  },
  previewLoading: false,
  isOrgExist :true,
  isOrgCorrect:false,
};

export const openingSlice = createSlice({
  name: "openings",
  initialState,
  reducers: {
    initOrgInfo: (state,action) => {
      state.orgInfo = action.payload;
    },
    updatePreviewLoading:(state,action)=>{
      state.previewLoading = action.payload;
    },
    setIsOrgExist:(state,action)=>{
      state.isOrgExist = action.payload.isOrgExist;
      state.isOrgCorrect = action.payload.isOrgCorrect;
    }
  },
});

export const { initOrgInfo,updatePreviewLoading,setIsOrgExist} = openingSlice.actions;
export default openingSlice.reducer;
