import React, { Component, ErrorInfo } from 'react';
import PageNotFound from './pages/pageNotFound/PageNotFound';

class ErrorBoundary extends Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error occurred:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <PageNotFound />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
